// StopWatch.js
// https://www.geeksforgeeks.org/create-a-stop-watch-using-reactjs/

import React, { useEffect, useState } from "react";
import "./StopWatch.css";
import Timer from "../Timer/Timer";

function Button1({handleStart}) {
    return(
        <div className="btn btn-one" 
            onClick={handleStart}>
            Start
        </div>
    )
}
function Button2({handleReset,handleResume}) {
    const a=[]
    a.push(<div className="btn btn-one"
        onClick={handleReset}>Reset</div>);
    a.push(<div className="btn btn-one"
        onClick={handleResume}>Resume</div>);
    return(a)
}
function Button3({handleRestart,handlePause,handleReset}) {
    const a=[]
    a.push(<div className="btn btn-one"
        onClick={handleRestart}>Restart</div>);
    a.push(<div className="btn btn-one"
        onClick={handlePause}>Pause</div>);
    a.push(<div className="btn btn-one"
        onClick={handleReset}>Reset</div>);
    return(a)
}

function StopWatch({writestate, time, setTime, isActive, setIsActive, isPaused, setIsPaused, 
isRunning, setIsRunning, start, setStart, cumul, setCumul}) {
const [borderCol, setBorderCol] = useState('darkcyan');

useEffect(() => {
    let interval = null;

    if (isActive && (!isPaused)) {
    interval = setInterval(() => {
        let current = new Date();
        let count = Math.floor((current - start));
        setTime(count+cumul);
    }, 50);
    }
    return (() => {
    clearInterval(interval);
    });
}, [isActive, isPaused, start, cumul]);

// isActive, isPaused: these determine the state
// intialize setTime(0)

// (1) F,F = initial state
// time is not advancing
// buttons: "start"-->{setStart(<cur date>),
// setCumul(0),(3)}

// (2) T, T = paused
// time is not advancing
// buttons: "reset"-->{setTime(0),(1)}, 
// "resume"--> {setCumul(start+time-<cur date>),(3)}

// (3) T, F = running
// time maintained at cumul + <cur date> - start 
// buttons: "restart"-->setCumul(start - <cur date>), "pause"-->(2), "reset"-->{setTime(0),(1)}

const handleStart = () => {
    setStart(new Date());
    setCumul(0);
    setIsActive(true);
    setIsPaused(false);
    setIsRunning(true);
};
const handleResume = () => {
    let d = new Date();
    let elapsed = Math.floor((d-start));
    setCumul(time-elapsed);
    setIsActive(true);
    setIsPaused(false);
    setIsRunning(true);
};
const handleRestart = () => {
    let d = new Date();
    setCumul(start - d);
    setBorderCol('blue')
    setTimeout(()=>{setBorderCol('darkcyan')},100)

};
const handlePause = () => {
    setIsActive(true);
    setIsPaused(true);
    setIsRunning(false);
};
const handleReset = () => {
    setTime(0);
    setIsActive(false);
    setIsPaused(false);
    setIsRunning(false);
};


return (
    <div className="stop-watch"
    style={{border: '2px solid '+borderCol }}

    >
        <Timer time={time} isRunning={isRunning}/>
        <div className="btn-grp">
            {
                (!isActive & !isPaused)?
                <Button1 handleStart={handleStart}/> :
                (isActive & isPaused ?
                    <Button2 
                        handleReset={handleReset} 
                        handleResume={handleResume}
                    /> :
                    <Button3
                        handleRestart={handleRestart}
                        handlePause={handlePause}
                        handleReset={handleReset}
                    />
                )
            }
        </div>
    </div>
);
}

export default StopWatch;
