export default function TextLog(
	{
		exerObj, setExerObj, 
        textvalue, setTextvalue,
        writestate, readstate, myval
	}
) {	  

	function handleChangeTextvalue(ev){
		setTextvalue(ev.target.value);
		writestate(exerObj, ev.target.value);
	}
		
	return(
		<textarea type='text' id='logboxid' className='textlog' 
			value={textvalue+myval} 
			onChange={(ev)=>{handleChangeTextvalue(ev)} } 
			/>
	);
}

