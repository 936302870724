import React, { useState, useEffect } from "react";
import "./PullCloud.css";

export default function PullCloud({readstate}) {
	const [textcolor, settextcolor] = useState('aqua');

	useEffect( ()=>{
		if(textcolor!=='aqua'){
			const mytimer = setInterval(() => {
				settextcolor('aqua');
			}, 1000);	
			// clearing interval
			return () => clearInterval(mytimer);	
		}
	});

	const handleClick = () => {
		settextcolor('yellow');
		readstate();
	};

	return (
		<span className='titlerefresh'
			style={{color:textcolor}}
			onClick={handleClick} >
			Pull Cloud Data
		</span>
	);
}

