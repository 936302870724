// App.js
// encryption and decryption, with userKey based on sub and salt, is working
// need to redesign the database so that each user writes to a unique part
// maybe do hash of sub, then submit that to the server as id to tag all the data


import React from "react";
import "./App.css";
import Settings from './Views/Settings.js';
import Workout from './Views/Workout.js';
import Review from './Views/Review.js';
import LoginButton from './Components/LoginButton.js';
import { useAuth0 } from "@auth0/auth0-react";
import CryptoJS from "crypto-js"; // Import the cryptographic library

import {
	BrowserRouter as Router,
	Link,
	Routes,
	Route,
	// Outlet
} from "react-router-dom";
import { useEffect, useState } from "react";
const url = process.env.REACT_APP_NODE_URL+'/exertrackerdb/query';
const salt ='adixaow'; // make sure other versions of the client use different salt

function App({UI_DEBUG, encryptData, decryptData}) {
	const [isLocalDataValid, setLocalDataValid] = useState(false);
    const [exerObj, setExerObj] = useState({100:{name:"walk",value:"1 mile",selected:false}});
	const [textvalue, setTextvalue] = useState('');
	const [myval, setMyval] = useState('');
	const [writeToken, setWriteToken] = useState(null);
	const [readToken, setReadToken] = useState(null);  
	const [time, setTime] = useState(0);
const [isActive, setIsActive] = useState(false);
const [isPaused, setIsPaused] = useState(false);
const [isRunning, setIsRunning] = useState(false);
const [start, setStart] = useState(0);
const [cumul, setCumul] = useState(0);



	var { isAuthenticated, getAccessTokenSilently } = useAuth0();
	if(UI_DEBUG=='no-auth') {
		console.log('no auth0');
		isAuthenticated = false;
		getAccessTokenSilently=null;
	}
	const exerparm = {
			exerObj, setExerObj, 
			textvalue, setTextvalue,
			writestate, readstate,
			time, setTime,
			isActive, setIsActive, isPaused, setIsPaused,
			isRunning, setIsRunning, start, setStart, cumul, setCumul,
			myval,
		}
// console.log(JSON.stringify(exerObj))

	async function writestate(myobj, mytextvalue) {
		if(!isLocalDataValid) return;
		let myToken;
		if(writeToken===null){
		  const accessToken = await getAccessTokenSilently({
			audience: 'https://sunfishapp.herokuapp.com/exertrackerdb',
			scope: "write:data",
		  });
		//   console.log('Got write token')
		  setWriteToken(accessToken);
		  myToken = accessToken;
		}
		else{
		  myToken = writeToken;
		}

		// Decode the JWT to access the 'sub' claim
		const payload = JSON.parse(atob(myToken.split('.')[1]));
		// console.log("Sub:", payload.sub);
		// console.log("Salt:", salt);
		const userKey=CryptoJS.SHA256(payload.sub + salt).toString(); // Generate a hash as the encryption key
		const uid=CryptoJS.SHA256(payload.sub).toString();
		// console.log("uid:", uid);
		const encmyobj=encryptData(JSON.stringify(myobj),userKey)
		const encmytextvalue=encryptData(JSON.stringify(mytextvalue),userKey)
		
		const userpayload = {uid: uid, 
				exerObj: myobj, log: mytextvalue};

		fetch(url, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json;charset=utf-8',
					  Authorization: `Bearer ${myToken}`},
			body: JSON.stringify(userpayload)
		}); 
		// let mymsg = {
		// 	method: 'PUT',
		// 	headers: { 'Content-Type': 'application/json;charset=utf-8',
		// 			  Authorization: `Bearer ${myToken}`},
		// 	body: JSON.stringify(mypayload)
		// }; 
		// console.log('Wrote to remote server '+JSON.stringify(mymsg).length+'bytes')
	}

	async function readstate() {
		setMyval('');

		setTimeout(() => {
			var messageBody = document.querySelector('#logboxid');
			if(messageBody!=null){
			  messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
			}
		}, 1000);		  
	

		if (!isAuthenticated) return;
		let myToken;
		if(readToken===null){
		  const accessToken = await getAccessTokenSilently({
			audience: 'https://sunfishapp.herokuapp.com/exertrackerdb',
			scope: "read:data",
		  });
		  setReadToken(accessToken);
		  myToken = accessToken;
		}
		else{
		  myToken = readToken;
		}

        // Decode the JWT to access the 'sub' claim
        const payload = JSON.parse(atob(myToken.split('.')[1]));
        // console.log("Sub:", payload.sub);
        // console.log("Salt:", salt);
		const userKey=CryptoJS.SHA256(payload.sub + salt).toString(); // Generate a hash as the encryption key
		// console.log('userKey:',userKey)
		const uid=CryptoJS.SHA256(payload.sub).toString();


        // retrieves from remote cloud DB, stores in local
        fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${myToken}`,
				"X-Custom-Header-uid": uid
			}})
        .then(res => res.json())
        .then(res=>{ 
			console.log('readstate fetch: ',res)
			if(typeof res.exerObj!='undefined'){
				console.log('readstate: valid data downloaded')
				setLocalDataValid(true);  
				setExerObj(res.exerObj)
				setTextvalue(res.log)
			}
			else if(res.uid==uid){
				console.log('readstate: server echo, valid uid')
				setLocalDataValid(true);  
				}
				else {
					console.log('readstate: server unreachable')
				}
		})
        .catch(err=>{
			// if(res==uid){
			// 	setLocalDataValid(true);  
			// 	console.log('readstate: server echo, valid uid')
			// }
			// else {
			// 	console.log('readstate: server not reachable')
			// }
		});
    }
	
	useEffect(()=>{
		// put any intialization code here
		// note that this is called before authenticated,
		// so cannot yet access server data
	}, []);

	if(UI_DEBUG!='no-auth') {
		if (!isAuthenticated) {
			return (
			  <div style={{
				  display:'flex',
				  justifyContent:'center',
				  width: '200px',
						  }}>
				  <LoginButton />;
			  </div>
			)
		  }
	  
	}


	return (
    		<>
			<Router>
				<div className='navtext'>
					<Link to="/workout" className='navtext'
						style={{textDecoration:'none'}}>
						<button className='navbtn'> Workout </button>
					</Link>
					<Link to="/review" className='navtext'
						style={{textDecoration:'none'}}>
						<button className='navbtn'> Review </button>
					</Link>
					<Link to="/settings" className='navtext'
						style={{textDecoration:'none'}}>
						<button className='navbtn'> Settings </button>
					</Link>
				</div>

				<Routes>
					<Route index element={<Workout {...exerparm}/>} />
					<Route path="/workout" 
						element={<Workout {...exerparm} />} />
					<Route path="/review" 
						element={<Review {...exerparm} />} />
					<Route path="/settings" 
						element={<Settings {...exerparm} />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;


