import React from "react";
import "./SelectButtons.css";

export default function SelectButtons(props) {
const ClearButton = (
	<span onClick={props.handleClear}>
		Clear workouts
	</span>
);
const SetButton = (
	<span onClick={props.handleSet}>
		Select workouts
	</span>
);
return (
	<span className="selectbuttons">{props.clear ? ClearButton : SetButton}</span>
);
}
