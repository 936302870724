import React, {useRef, useState, useEffect} from 'react';
import "../../App.css";
 
export default function TextBox(props) {
    const textareaRef = useRef(); 
// to point to the input box child component, so that handleCursor()
// can access it

	const [val, setVal] = useState(''); // the text inside the input box
	const [total, setTotal] = useState(0); // total number of reps
	const [cursorPos, setCursorPos] = useState(0);

	const handleChange = (e) => {
		let myval = e.target.value;

		const regex = /(?:^|\s)(\d+)(?=\s|$)/g;
		let match;
		let mytotal = 0;
		while ((match = regex.exec(myval)) !== null) {
			mytotal += parseInt(match[1], 10); 
		}
		// console.log('total='+mytotal)
		setVal(myval);
		setTotal(mytotal);
		// update myval and mytotal, which are the variables tracking these inside the TextBox component

		props.onChange(myval); // propagate the new text value up the parents stack, so we can update the value for this id in exerObj

	}

	const handleCursor = () => {
		// this function is entered when I click outside the text box, so if the text box has not yet been modified, then val would be empty. therefore, need to fetch my own value for the text box. And the same for total.
		let myval = val;
		let mytotal = total;
		if(val==''){
			myval=textareaRef.current.value;
			const reg = /(?:^|\s)(\d+)(?=\s|$)/g;
			let match;
			mytotal = 0;
			while ((match = reg.exec(myval)) !== null) {
				mytotal += parseInt(match[1], 10); 
			}
		}
		setCursorPos(textareaRef.current.selectionStart);
		const regex = /\s(\d+)Total/g;
		let match=regex.exec(myval);
		if (match !== null){
			let oldpat = match[1]+'Total'
			let newpat = mytotal+'Total'
			myval = myval.replace(oldpat,newpat)
		}
		else {
			myval = myval+' '+mytotal+'Total'
		}
		setVal(myval);
		setTotal(mytotal);
		props.onChange(myval);

	}
		
	const textStyle = {
		fontFamily: 'Courier New, monospace', // Set the font-family to a monospaced font
		'fontSize': '20px',
		width: `${val.length}ch`,
		minWidth: '100%'
	};
	const barStyle = {
		fontFamily: 'Courier New, monospace', // Set the font-family to a monospaced font
		'fontSize': '20px',
		height: '1px',
		outline:'none',
		backgroundColor: 'aqua', 
		width: `${Math.max(0,val.substring(0,cursorPos).length-1)}ch`, // Set the width based on the number of characters
	};
	
	useEffect(() => {
		// console.log('useffect: cursor='+cursorPos+
		// 	' text='+val.substring(0,cursorPos));
	}, [cursorPos,val]);

	return (
		<div style={{
			display:'flex',
			flexDirection:'column',
			width:'55%',
		}}>
			<input
				style={textStyle}
				value={props.value} 
				ref={textareaRef}
				onChange={(e)=>handleChange(e)} 
				onBlur={handleCursor}
			/>
			<input style={barStyle}/>
		</div>

	);
	
}




