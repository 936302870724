// Adapted from: https://www.geeksforgeeks.org/create-a-stop-watch-using-reactjs/

import React, { useState } from "react";
import "./ToggleSelect.css";
import SelectButtons from "../SelectButtons/SelectButtons";

export default function ToggleSelect(
	{
		exerObj, setExerObj, 
        textvalue, setTextvalue,
        writestate, readstate,
	}
) {
const [isClear, setIsClear] = useState(true);

const handleSet = () => {
	setIsClear(true);
	let newobj = JSON.parse(JSON.stringify(exerObj));
	for (const [key, entry] of Object.entries(newobj)) {
		entry.selected=true;
	}
	setExerObj(newobj);
}

const handleClear = () => {
	setIsClear(false);
	let newobj = JSON.parse(JSON.stringify(exerObj));
	for (const [key, entry] of Object.entries(newobj)) {
		entry.selected=false;
	}
	setExerObj(newobj);
}

return (
	<SelectButtons
		clear={isClear}
		handleClear={handleClear}
		handleSet={handleSet}
	/>
);
}
