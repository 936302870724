import React from "react";
import "../Button.css";


export default function TextBottom() {	  
  
	const handleBottom = () => {
		var messageBody = document.querySelector('#logboxid');
		if(messageBody!=null){
		  messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
		}
	};
	
	return (
		<button className="btn"
			onClick={handleBottom} >
			Bottom
		</button>
	);
}

