import * as React from 'react'
import { useState } from "react";
import './MyTable.css'
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme


function MyTable({colHeadings,rowData}) {
  // Column Definitions: Defines & controls grid columns.
  // Row Data: The data to be displayed.
  const mycol = [];
  // for(let f of colHeadings){
  //   if(f==='Exercise'){
  //     mycol.push({field:f, pinned: true});
  //   }
  //   else {
  //     mycol.push({field:f});
  //   }
  // }   

  for (let f of colHeadings) {
    if (f === 'Exercise') {
        mycol.push({
            field: f,
            pinned: true,
            wrapText: true,  // Enable text wrapping
            // autoHeight: true, // Dynamically adjust row height
        });
    } else {
        mycol.push({
            field: f,
            wrapText: true,  // Enable text wrapping
            width: 150,
            // autoHeight: true, // Dynamically adjust row height
        });
    }
  }

  return (

    // Container with theme & dimensions
      <div className="ag-theme-quartz" 
      style={{ height: 750, 'text-align': 'left'}}
      >
        {/* The AG Grid component */}
        <AgGridReact rowData={rowData} columnDefs={mycol} 
        rowHeight={80}
        />

      </div>
      )
}

export default MyTable;