import React from "react";
import "../App.css";
import MyTable from "../Components/MyTable.js";

// strip space character from start and end of str
function strip(str) {
    const remove = ' ';
    while (str.length > 0 && remove == str.charAt(0)) {
      str = str.substr(1);
    }
    while (str.length > 0 && remove == str.charAt(str.length - 1)) {
      str = str.substr(0, str.length - 1);
    }
    return str;
}

export default function Review(
    {
		exerObj, setExerObj, 
        textvalue, setTextvalue,
        writestate, readstate,
	}
) {

    function generateTable(textblock) {
        const lines = textblock.split(/\r?\n|\r|\n/g);
        const dateList = [];
        const actionList = [];
        for (const line of lines) {
            try {
                if(line.indexOf('//')!=0){ // line = a line in the log
                    const [datetime, exercisesStr] = line.split(' : ',2);
                    const date = datetime.split(',')[0]; // Discard the time
                    let actions = exercisesStr.split(', '); // ["walk 1","run 2"]
                    actions = actions.map(str => strip(str)) // remove spaces
                    dateList.push(strip(date)); // [date1, date2, date3, ...] (may have dups)
                    actionList.push(actions); // [["walk 1","run 2"], ["pop 2", "push 5"], ...]   
                }
                // else {
                //     alert('comment='+line)
                // }
            } catch {
            }
        }

        // d is Map of date to list of actions
        // e is Set of exercise-names
        const d = new Map();
        const e = new Set();
        for (let i = 0; i < dateList.length; i++) { // iterate over each line in the log
            if (!d.has(dateList[i])) {
                d.set(dateList[i], actionList[i]);
            } else {
                d.set(dateList[i], d.get(dateList[i]).concat(actionList[i]));
            } // d maps each date to ["walk 1","run 2"], consolidating dates
            // so that each date appears only once in d.keys()
        }
        // now we parse the actions into exercise-name and description
        // dtable = nested map whose values are maps whose keys are exercise-names
        // e.g., {'1/1/2020':{'run':'2 miles','walk':'1 mile'}}
        // so we can look up a date and get a new map, and this map 
        // lets us look up each exercise-name and get the action
        // note: undefined behavior when a single date has the same exercise more than once
        const dtable = new Map();
        for (const [date, alist] of d.entries()) { // this is the table column for that date
            // alist is the list of (exercise-name + description) for that column
            dtable.set(date, new Map());
            const exernamelist = alist.map(action => strip(action.split(' ')[0].toLowerCase()));
            // exernamelist is a list of only the "verbs" or exercise-names for that column
            if (exernamelist.length !== alist.length) {
                console.log('********* Error in parsing exernamelist!!');
            }
            for (let i = 0; i < alist.length; i++) {
                const action = strip(alist[i]); // "run 2 miles"
                if(action.includes(' ')){
                    const j = action.indexOf(' ');
                    const description = strip(action.substr(j+1)); // "2 miles"
                        dtable.get(date).set(exernamelist[i], description); // {date: {"run": "2 miles"}
                }
                else {
                    dtable.get(date).set(exernamelist[i], 'X');
                }
                e.add(exernamelist[i]) // e = de-dup'd set of exercise-names (the row labels)
            }
        }
        // Sort dates to construct column headings
        const sortedDates = [...d.keys()].sort((a, b) => new Date(b) - new Date(a));
        let colHeadings = ['Exercise', ...sortedDates];

        // now need to sort the list to start in same order as exerObj
        let exerlist = Object.entries(exerObj);
        exerlist.sort((a, b) => a[0]-b[0]);
        const rows = [];
        for(let entry of exerlist){
            if(e.has(entry[1].name)){
                rows.push(entry[1].name)
                e.delete(entry[1].name)
            }
        }
        const rownames = rows.concat(Array.from(e)); // the sorted rows, plus the remaining exercise-names

        // Construct data rows
        const datamatrix = []
        for (const myrow of rownames) {
            const line = [myrow];
            for (const mydate of sortedDates) {
                if(dtable.get(mydate).has(myrow)) {
                    line.push(dtable.get(mydate).get(myrow));
                }
                else {
                    line.push(' ');
                }
            }
            datamatrix.push(line);
        }
        let myRowData = [];
        for(let rowlist of datamatrix){
            const a = {};
            for(let i=0; i<rowlist.length; ++i){
                a[colHeadings[i]]=rowlist[i];
            }
            myRowData.push(a);
        }
        return [colHeadings,myRowData];
    }


        // let mytext = `7/13/2021, 11:06:47 AM : Squat 12, Pushup 52
        // 7/14/2021, 4:02:16 PM : Pushup 55, Squat 10
        // `
    const [colHeadings,rowData] = generateTable(textvalue);
	return (
		<div 
        // style={{color:'white'}}
        >
			<MyTable colHeadings={colHeadings} rowData={rowData}/>
		</div>
	);
  };
