import React, { useState, useEffect } from "react";
import "./SubmitButton.css";


export default function SubmitButton(
	{
		exerObj, setExerObj, 
        textvalue, setTextvalue,
        writestate, readstate,
	}
) {
	const tc = 'white'
	const [textcolor, settextcolor] = useState(tc);

	useEffect( ()=>{
		if(textcolor!==tc){
			const mytimer = setInterval(() => {
				settextcolor(tc);
			}, 1000);	
			// clearing interval
			return () => clearInterval(mytimer);	
		}
	});

	const handleSubmit = () => {
		settextcolor('orange');
		const d = new Date();
		let timestamp = d.toLocaleString()+' ';
		let newtext = timestamp+':  ';
		for (const [key, entry] of Object.entries(exerObj)) {
		  if(entry.selected){
			newtext += entry.name;
			if(entry.value){
			  newtext += ' ' + entry.value +', ';
			}
			else {
			  newtext += ', ';
			}
		  }
		}
		newtext = newtext.slice(0,-2);
		newtext += '\r\n';
		let tlog = textvalue.slice();
		tlog+=newtext;
		setTextvalue(tlog);
		var messageBody = document.querySelector('#logboxid');
		if(messageBody!=null){
		  messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
		}
		writestate(exerObj, tlog);
	  }

	return (
		<button className='submitbutton'
			style={{color:textcolor, 'fontSize': textcolor===tc ? '20px' : '40px'}}
			onClick={handleSubmit} >
			+
		</button>
	);
}

