import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../Components/Button.css";
import "../App.css";
import { Navigate } from "react-router-dom";
const serverUrl = process.env.REACT_APP_NODE_URL;

export default function Settings(
	{
		exerObj, setExerObj, 
        textvalue, setTextvalue,
        writestate, readstate,
	}
){
  const Listexer = ()=>{
    let ret = [];
    let exerlist = Object.entries(exerObj);
	  exerlist.sort((a, b) => a[0]-b[0]);
	  for(let entry of exerlist){
      ret.push(<div>{entry[0]}  {entry[1].name}</div>)
    }
    return ret;
  }
  function addexer(idx,ename){
    let newobj = JSON.parse(JSON.stringify(exerObj));
    newobj[idx] = {name: ename, value: "", selected: false};
    setExerObj(newobj);
    writestate(newobj, textvalue);
  }
  function delexer(idx){
    let newobj = JSON.parse(JSON.stringify(exerObj));
    delete newobj[idx];
    setExerObj(newobj);
    writestate(newobj, textvalue);
  }

  return(
    <div className='label' style={{color: 'white'}}>
      <h2 className='navtext'>
        Remote server at: {process.env.REACT_APP_NODE_URL}</h2>
      <h2 className='navtext'>Current exercises:</h2>
      <Listexer />
      <br />

      <div>
        <h3 className='navtext'>Index</h3>
        <form className='valuetextbox' >
          <input className='valueinput' type='text' id='newindex' />
        </form>
        <h1 className='navtext'>Exercise</h1>
        <form className='valuetextbox' >
          <input className='valueinput' type='text' id='newexercise' />
        </form>
      </div>
      <button className="btn" onClick={()=>{
        addexer(document.getElementById('newindex').value,
        document.getElementById('newexercise').value);
        }}>
          Save
      </button>
      <button className="btn" onClick={()=>{
        delexer(document.getElementById('newindex').value);
        }}>
          Delete by index
      </button>
    </div>
  );	  
}
